import React from "react";
import Separator from "../../components/Separator";
import TestimonialLogoimage from "../../assets/images/testimonial-logoimage.png";
import EndLogo from "../../assets/images/end-logo.png";
import Choose2Image1 from "../../assets/images/choose2-image1.png";
import Choose2Image2 from "../../assets/images/choose2-image2.png";
import Heading from "../../components/Heading";
import Button from "../../components/Button";
const WhyChooseUs: React.FC = () => {
  return (
    <>
      <section className="servicepage-choosesection">
        <figure className="mb-0 choose-logoimage">
          <img src={TestimonialLogoimage} alt="" className="img-fluid" />
        </figure>
        <div className="container">
          <Separator classes="mb-0 end-logo" image={EndLogo} />
          <div className="content">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="choose_wrapper">
                  <figure className="choose2-image1 mb-0">
                    <img src={Choose2Image1} alt="" className="img-fluid" />
                  </figure>
                  <figure className="choose2-image2 mb-0">
                    <img src={Choose2Image2} alt="" className="img-fluid" />
                  </figure>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="choose_content">
                  <Heading tag="h6" text="Why Choose Us" />
                  <Heading
                    tag="h2"
                    text="Illuminate your life with intelligence."
                  />
                  <div className="lower-content">
                    <ul className="list-unstyled">
                      <li>
                        <span>01.</span>
                        <h4>Experienced</h4>
                      </li>
                      <li>
                        <span>02.</span>
                        <h4>Quality</h4>
                      </li>
                      <li>
                        <span>03.</span>
                        <h4>Automations</h4>
                      </li>
                      <li>
                        <span>04.</span>
                        <h4>Support</h4>
                      </li>
                    </ul>
                    <Button
                      link="/about"
                      text="Read More"
                      classes="read_more"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default WhyChooseUs;
