import React, { useState, useEffect } from "react";
import LogoSection from "../../sections/LogoSection";
import AboutSection from "../../sections/About";
import ServiceSection from "../../sections/ServiceSection";
import ChooseSection from "../../sections/ChooseSection";
import PortfolioSection from "../../sections/PortfolioSection";
import TestimonialSection from "../../sections/TestimonialSection";
import ContactSection from "../../sections/ContactSection";
import FaqSection from "../../sections/FaqSection";
import BannerSection from "../../sections/BannerSection";
import { FetchHomeBannerData } from "../../api/api.service";
import { HomeBannerParams } from "../../api/apiSchema";
import Missionsection from "../../sections/MissionSection";
import Pricing from "../../sections/Pricing";
const Home = () => {
  return (
    <>
      {/* <BannerSection /> */}
      <AboutSection />
      <ChooseSection />
      <ServiceSection />
      <Pricing />
      <Missionsection />
      <ContactSection />
      <TestimonialSection showLogoImage={true} />
    </>
  );
};

export default Home;
