import React, { useState, useEffect } from "react";
import Switch from "../../components/Switch";
import PackageBox from "../../components/PackageBox";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import BootCalculator from "../../components/BootCalculator";
import carIcon from "../../assets/icons/car.svg";
import arrowIcon from "../../assets/icons/arrowsvg.svg";
import bulletIcon from "../../assets/icons/bullter.svg";
import rocketIcon from "../../assets/icons/rocket-fly-1.svg";
import bg1 from "../../assets/images/contact-backgroundimage1.png";
import bg2 from "../../assets/images/contact-backgroundimage2.png";
const Pricing: React.FC = () => {
  const [selectedType, setSelectedType] = useState("starter");
  const [time, setTime] = useState("6h");
  const [packageName, setPackageName] = useState("Starter Booster 9 Sol");
  const [calculatorValue, setCalculatorValue] = useState<number>(1);
  const [estimateVolume, setEstimateVolume] = useState<number>(0.34);

  const handleValueChange = (newValue: number) => {
    setCalculatorValue(newValue);
  };

  const handleSelectType = (type: string) => {
    setSelectedType(type);
  };

  const handleSelectTime = (changedTime: string) => {
    setTime(changedTime);
  };

  useEffect(() => {
    let volume;
    switch (selectedType) {
      case "starter":
        volume = 0.34;
        break;
      case "sigma":
        volume = 0.68;
        break;
      case "alpha":
        volume = 1.13;
        break;
      case "enterprise":
        volume = 2.27;
        break;
      default:
        volume = 0.34;
    }

    setEstimateVolume(volume);
  }, [selectedType]);

  console.log("time ss", time);
  return (
    <>
      <div className="section_pricing" id="pricing">
        <figure className="pricing-backgroundimage1 mb-0">
          <img decoding="async" src={bg1} className="img-fluid" alt="" />
        </figure>
        <figure className="pricing-backgroundimage2 mb-0">
          <img decoding="async" src={bg2} className="img-fluid" alt="" />
        </figure>
        <figure className="pricing-backgroundimage3 mb-0">
          <img decoding="async" src={bg1} className="img-fluid" alt="" />
        </figure>
        <figure className="pricing-backgroundimage4 mb-0">
          <img decoding="async" src={bg2} className="img-fluid" alt="" />
        </figure>
        <div className="position-relative">
          <div className="container">
            <div className="priceingHeader">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                  <p data-aos="fade-up">
                    Discover our tailored packages to rapidly increase your
                    project's trading volume and holder count. Choose your
                    package now to get started!
                  </p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 d-flex justify-content-center">
                  <Switch handleSelectTime={handleSelectTime} />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                  <p
                    className="rightText"
                    data-aos="fade-up"
                    style={{ fontSize: "12px" }}
                  >
                    *VBot operates as long as there are funds in the wallet.
                    Depending on the selected mode, it consumes SOL to cover
                    Raydium fees every 6 or 24 hours. Please note that network
                    conditions and a decreasing token price may cause the funds
                    to be depleted more quickly.
                  </p>
                </div>
              </div>
            </div>
            <div className="pricingBody">
              <div className="row justify-content-center">
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <PackageBox
                    type="starter"
                    onClick={() => {
                      handleSelectType("starter");
                      setPackageName("Starter Package 9 Sol");
                    }}
                    selectedType={selectedType}
                    time={time}
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <PackageBox
                    type="sigma"
                    onClick={() => {
                      handleSelectType("sigma");
                      setPackageName("Sigma Plan 18 Sol");
                    }}
                    selectedType={selectedType}
                    time={time}
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <PackageBox
                    type="alpha"
                    onClick={() => {
                      handleSelectType("alpha");
                      setPackageName("Alpha Pack 30 Sol");
                    }}
                    selectedType={selectedType}
                    time={time}
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <PackageBox
                    type="enterprise"
                    onClick={() => {
                      handleSelectType("enterprise");
                      setPackageName("Enterprise Booster 60 Sol");
                    }}
                    selectedType={selectedType}
                    time={time}
                  />
                </div>
              </div>

              <div className="instruction">
                <ul>
                  <li>
                    <sup>1</sup>Amount of fresh wallets used to create a volume
                  </li>
                  <li>
                    <sup>2</sup>The number of transactions per minute might be
                    slightly different depending on network congestion.{" "}
                  </li>
                  <li>
                    <sup>3</sup>Orbitt MM does not charge any extra trading fees
                    on top of transactions.
                  </li>
                </ul>
              </div>
              <div className="calculatorSectionMain">
                <div className="row align-items-start">
                  <div className="col-md-4 col-sm-12 col-12">
                    <div className="calculatorTable">
                      <h2>
                        Calculate <span>your estimate volume</span>
                      </h2>
                      <p>
                        Get an instant estimate of your trading volume potential
                        with Discover the power of your project's growth using
                        the calculator!
                      </p>
                      <a href="#">
                        <button className="accessBtn">
                          <span>
                            Access VBot
                            <i className="circle fa-regular fa-angle-right"></i>
                          </span>
                        </button>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-8 col-sm-12 col-12">
                    <div className="calculatorSection">
                      <div className="packagBlock">
                        <div className="packageDetail">
                          Package:
                          <span>{packageName}</span>
                        </div>
                        <div className="packages">
                          <ul>
                            <li>
                              <div
                                className={`iconBox ${selectedType == "starter" && "selected"}`}
                                onClick={() => {
                                  handleSelectType("starter");
                                  setPackageName("Starter Booster 9 Sol");
                                }}
                              >
                                <img src={carIcon} alt="" className="icon" />
                              </div>
                            </li>
                            <li>
                              <div
                                className={`iconBox ${selectedType == "sigma" && "selected"}`}
                                onClick={() => {
                                  handleSelectType("sigma");
                                  setPackageName("Sigma Plan 18 Sol");
                                }}
                              >
                                <img src={arrowIcon} alt="" className="icon" />
                              </div>
                            </li>
                            <li>
                              <div
                                className={`iconBox ${selectedType == "alpha" && "selected"}`}
                                onClick={() => {
                                  handleSelectType("alpha");
                                  setPackageName("Alpha Pack 30 Sol");
                                }}
                              >
                                <img src={bulletIcon} alt="" className="icon" />
                              </div>
                            </li>
                            <li>
                              <div
                                className={`iconBox ${selectedType == "enterprise" && "selected"}`}
                                onClick={() => {
                                  handleSelectType("enterprise");
                                  setPackageName("Enterprise Booster 60 Sol");
                                }}
                              >
                                <img src={rocketIcon} alt="" className="icon" />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="packagBlock">
                        <div className="packageDetail">
                          Your token price change
                        </div>
                        <div className="packages">
                          <BootCalculator
                            packageType={selectedType}
                            onValueChange={handleValueChange}
                          />
                        </div>
                      </div>

                      <div className="packagBlock">
                        <div className="packageDetail">Solana Price</div>
                        <div className="packages">
                          <span>$150</span>
                        </div>
                      </div>

                      <div className="packagBlock volume">
                        <div className="packageDetail">
                          <span>Estimate Volume Boost</span>
                        </div>
                        <div className="packages">
                          <span className="volumeBoost">
                            ${(estimateVolume * calculatorValue).toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Pricing;
