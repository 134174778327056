import React from "react";
import Heading from "../../components/Heading";

const Missionsection: React.FC = () => {
  return (
    <section className="about-section mission">
      <div className="container position-relative">
        <div className="lower-column" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <Heading
                tag="h3"
                text="Our Mission Is To Automate The Development Of Crypto Projects and save your time and money!"
              />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-4 col-4 missionBlock">
              <div className="content content1">
                <span className="value">1K</span>
                <sup className="mb-0 plus">+</sup>
                <span className="heading">1k Projects served</span>
                <p className="mb-0 text text1 text-size-16">
                  More than 2000 Crypto projects have used our solutions.
                </p>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4 col-4 missionBlock">
              <div className="content content2">
                <span className="value">1K</span>
                <sup className="mb-0 plus">+</sup>
                <span className="heading">Happy Clients</span>
                <p className="mb-0 text text2 text-size-16">
                  Customer satisfaction is our goal.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-4 col-4 missionBlock">
              <div className="content content3">
                <span className="value">11</span>
                <sup className="mb-0 plus">+</sup>
                <span className="heading">Years of Experience</span>
                <p className="mb-0 text text3 text-size-16">
                  Our software house has been developing crypto from the
                  beginning.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Missionsection;
