import React from "react";
import Banner from "../../components/Banner";
import LogoSection from "../../sections/LogoSection";
import ContactUsForm from "../../sections/ContactUsForm";
import ContactInfo from "../../sections/ContactInfo";
import ContactMap from "../../sections/ContactMap";
import "./index.scss";
const ContactUs: React.FC = () => {
  return (
    <>
      <Banner title="Contact Us" breadCrumb="Contact" />
      <LogoSection />
      <ContactUsForm />
      <ContactInfo />
      <ContactMap />
    </>
  );
};

export default ContactUs;
