import React from "react";
import Separator from "../../components/Separator";
import EndLogo from "../../assets/images/end-logo.png";
const ContactInfo: React.FC = () => {
  return (
    <>
      <div className="contactpage-section">
        <div className="container">
          <Separator image={EndLogo} classes="mb-0 end-logo" />
          <div className="content">
            <div className="row" data-aos="fade-up">
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="contact-box">
                  <div className="box-image">
                    <figure className="mb-0 icon">
                      <img
                        src="./assets/images/contact3-phoneicon.png"
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="box-content">
                    <p>
                      <a
                        href="tel:+134567890"
                        className="text-decoration-none text-size-18"
                      >
                        +1 ( 345 67 89 0 )
                      </a>
                    </p>
                    <p className="mb-0">
                      <a
                        href="tel:+234567890"
                        className="text-decoration-none text-size-18"
                      >
                        +2 ( 345 67 89 0 )
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="contact-box">
                  <div className="box-image">
                    <figure className="mb-0 icon">
                      <img
                        src="./assets/images/contact3-emailicon.png"
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="box-content">
                    <p>
                      <a
                        href="mailto:info@futureai.com"
                        className="text-decoration-none text-size-18"
                      >
                        info@futureai.com
                      </a>
                    </p>
                    <p className="mb-0">
                      <a
                        href="mailto:sales@futureai.com"
                        className="mb-0 text-decoration-none text-size-18"
                      >
                        sales@futureai.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="contact-box">
                  <div className="box-image">
                    <figure className="mb-0 icon">
                      <img
                        src="./assets/images/contact3-locationicon.png"
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="box-content">
                    <p className="mb-0 text text-size-18">
                      King Street Melbourne, 3000, Australia
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactInfo;
