import React from "react";
import Banner from "../../components/Banner";
import LogoSection from "../../sections/LogoSection";
import ServiceSection from "../../sections/ServiceSection";
import WhyChooseUs from "../../sections/WhyChooseUs";
import ContactSection from "../../sections/ContactSection";
import FaqSection from "../../sections/FaqSection";
const Service: React.FC = () => {
  return (
    <>
      <Banner title="Our Services" breadCrumb="Services" />
      <LogoSection />
      <ServiceSection classes="servicepage-section" showSeparator={false} />
      <WhyChooseUs />
      <ContactSection />
      <FaqSection />
    </>
  );
};
export default Service;
