import React from "react";
import ContactLogoimage from "../../assets/images/contact3-logoimage.png";
import Heading from "../../components/Heading";
const ContactUsForm: React.FC = () => {
  return (
    <>
      <section className="contactinfo-section">
        <figure className="mb-0 contact3-logoimage">
          <img src={ContactLogoimage} alt="" className="img-fluid" />
        </figure>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="contactinfo_content">
                <Heading tag="h6" text="Need Help?" />
                <Heading tag="h2" text="Get in Touch With Us" />
              </div>
            </div>
          </div>
          <div className="info-content" data-aos="fade-up">
            <form id="contactpage" method="POST" action="#">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group mb-0">
                        <label>Name:</label>
                        <input
                          type="text"
                          className="form_style"
                          placeholder="Enter your name"
                          name="name"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group mb-0">
                        <label>Email:</label>
                        <input
                          type="email"
                          className="form_style"
                          placeholder="Enter your email"
                          name="emailid"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group mb-0">
                        <label>Subject:</label>
                        <input
                          type="text"
                          className="form_style"
                          placeholder="Add Something"
                          name="subject"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  <div className="row">
                    <div className="col-12">
                      <div className=" form-group mb-0">
                        <label>Message:</label>
                        <textarea
                          className="form_style"
                          placeholder="Write your message"
                          rows={3}
                          name="msg"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="manage-button">
                        <button
                          type="submit"
                          className="send_now text-white text-decoration-none"
                        >
                          Send Message
                          <i className="circle fa-regular fa-angle-right"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};
export default ContactUsForm;
