import React from "react";
import Separator from "../../components/Separator";
import Heading from "../../components/Heading";
import ValueIcon from "../../assets/images/value-icon1.png";
import ValueIcon2 from "../../assets/images/value-icon2.png";
import ValueIcon3 from "../../assets/images/value-icon3.png";
import SeparatorImg from "../../assets/images/end-logo.png";

const ValueSection: React.FC = () => {
  return (
    <section className="value-section">
      <div className="container">
        <Separator classes="mb-0 end-logo" image={SeparatorImg} />
        <div className="content">
          <div className="row">
            <div className="col-12">
              <div className="value_content">
                <Heading tag="h6" text="Our Value" />
                <Heading
                  tag="h2"
                  text="The Power Of VBot Intelligence."
                />
              </div>
            </div>
          </div>
          <div className="row" data-aos="fade-up">
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="value-box">
                <figure className="value-icon">
                  <img src={ValueIcon} alt="" className="img-fluid" />
                </figure>
                <span>Vission</span>
                <p className="text-size-16">
                  Lorem ipsum dolor sit amet, consectet ur adipiscing elit, sed
                  do eiusmod tempor incie magna aliqua.
                </p>
                <a className="read_more text-decoration-none" href="/service">
                  Read More
                  <i className="fa-solid fa-chevron-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="value-box">
                <figure className="value-icon">
                  <img src={ValueIcon2} alt="" className="img-fluid" />
                </figure>
                <span>Mission</span>
                <p className="text-size-16">
                  Cing elit, sed do eiusmod tempor incidi dunt ut labore et
                  dolore magna aliqua.Lor em ipsum dolor sit amet.
                </p>
                <a className="read_more text-decoration-none" href="/service">
                  Read More
                  <i className="fa-solid fa-chevron-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="value-box">
                <figure className="value-icon">
                  <img src={ValueIcon3} alt="" className="img-fluid" />
                </figure>
                <span>Motto</span>
                <p className="text-size-16">
                  empor incididunt ut labore et dolore magna aliqua.Lorem ipsum
                  dolor sit amet, conse ctetur adipis
                </p>
                <a className="read_more text-decoration-none" href="/service">
                  Read More
                  <i className="fa-solid fa-chevron-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ValueSection;
