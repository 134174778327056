import React, { useState } from "react";
import Logo from "../../assets/images/3d-logo.webp";
import Navigation from "./Navigation";
const Header: React.FC = () => {
  const [expnadMenu, setExpandMenu] = useState(false);

  return (
    <>
      <header className="header">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light">
            <a className="navbar-brand" href="/">
              <figure className="mb-0 banner-logo">
                <span className="leftEye eye"></span>
                <span className="rightEye eye"></span>
                <img src={Logo} alt="" className="img-fluid" />
              </figure>
            </a>
            <button
              className={`navbar-toggler ${!expnadMenu && "collapsed"}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setExpandMenu(!expnadMenu)}
            >
              <span className="navbar-toggler-icon"></span>
              <span className="navbar-toggler-icon"></span>
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`collapse navbar-collapse ${expnadMenu && "show"}`}
              id="navbarNav"
            >
              <Navigation onClick={() => setExpandMenu(!expnadMenu)} />
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};
export default Header;
