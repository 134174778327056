import React from "react";

const ContactMap: React.FC = () => {
  return (
    <>
      <div className="contact_map_section">
        <div className="container">
          <iframe
            src="https://maps.google.com/maps?q=21KingStreetMelbourne,3000,Australia&amp;t=&amp;z=10&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default ContactMap;
