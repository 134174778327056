import React from "react";
import BannerUperDots from "../../assets/images/banner-upperdots.png";
import BannerLowerDots from "../../assets/images/banner-upperdots.png";
import "./index.scss";

interface props {
  title: string;
  breadCrumb: string;
}
const Banner: React.FC<props> = ({ title, breadCrumb }) => {
  return (
    <div className="future_of_robotics sub-banner">
      <section className="banner-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="banner_content">
                <div className="box">
                  <span className="mb-0">Home</span>
                  <span className="tilde">~</span>
                  <span className="mb-0 box_span">{breadCrumb}</span>
                </div>
                <h1 className="mb-0">{title}</h1>
                <figure className="mb-0 banner-upperdot">
                  <img className="img-fluid" src={BannerUperDots} alt="" />
                </figure>
                <figure className="mb-0 banner-lowerdot">
                  <img className="img-fluid" src={BannerLowerDots} alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Banner;
