import React, { useCallback, useState, useEffect } from "react";
import EndLogo from "../../assets/images/end-logo.png";
import TestimonialQuoteimage from "../../assets/images/testimonial-quoteimage.png";
import TestimonialImage1 from "../../assets/images/testimonial-image1.png";
import TestimonialImage2 from "../../assets/images/testimonial-image2.png";
import TestimonialPrevarrow from "../../assets/images/testimonial-prevarrow.png";
import TestimonialNextarrow from "../../assets/images/testimonial-nextarrow.png";
import TestimonialImage3 from "../../assets/images/testimonial-image3.png";
import Heading from "../../components/Heading";
import useEmblaCarousel from "embla-carousel-react";
import ServiceLogoimage from "../../assets/images/service-logoimage.png";

interface props {
  showLogoImage?: boolean;
}
const TestimonialSection: React.FC<props> = ({ showLogoImage }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop: true,
      align: "start",
      containScroll: "keepSnaps",
    },
    []
  );
  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi]
  );
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  );

  // const onSelect = useCallback(() => {
  //   if (!emblaApi) return;
  //   setCurrentIndex(emblaApi.selectedScrollSnap());
  // }, [emblaApi]);

  //  useEffect(() => {
  //   if (!emblaApi) return;
  //   emblaApi.on("select", onSelect);
  // }, [emblaApi, onSelect]);

  return (
    <>
      <section className="testimonial-section">
        {showLogoImage && (
          <figure className="mb-0 testimonial-logoimage">
            <img src={ServiceLogoimage} alt="" className="img-fluid" />
          </figure>
        )}
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-12">
                <div className="testimonial_content">
                  <Heading tag="h6" text="Testimonials" />
                  <Heading tag="h2" text="What Our Customers Say About Us" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-1 col-md-1 col-sm-1 col-12"></div>
              <div className="col-lg-10 col-md-10 col-sm-10 col-12">
                <div className="testimonial_wrapper position-relative">
                  <figure className="testimonial-quoteimage mb-0">
                    <img
                      src={TestimonialQuoteimage}
                      alt=""
                      className="img-fluid invert_effect"
                    />
                  </figure>
                </div>
                <div
                  id="testimonialcarousel"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <ul className="carousel-indicators">
                    <li
                      onClick={() => {
                        emblaApi && emblaApi.scrollTo(0);
                        setCurrentIndex(0);
                      }}
                      className={currentIndex === 0 ? "active" : ""}
                    >
                      <figure className="mb-0">
                        <img
                          src={TestimonialImage1}
                          alt=""
                          className="img-fluid invert_effect"
                        />
                      </figure>
                    </li>
                    <li
                      onClick={() => {
                        emblaApi && emblaApi.scrollTo(1);
                        setCurrentIndex(1);
                      }}
                      className={currentIndex === 1 ? "active" : ""}
                    >
                      <figure className="mb-0">
                        <img
                          src={TestimonialImage2}
                          alt=""
                          className="img-fluid invert_effect"
                        />
                      </figure>
                    </li>
                    <li
                      onClick={() => {
                        emblaApi && emblaApi.scrollTo(2);
                        setCurrentIndex(2);
                      }}
                      className={currentIndex === 2 ? "active" : ""}
                    >
                      <figure className="mb-0">
                        <img
                          src={TestimonialImage3}
                          alt=""
                          className="img-fluid invert_effect"
                        />
                      </figure>
                    </li>
                  </ul>
                  <div className="testimonial_carousel position-relative">
                    <div className="carousel-inner">
                      <div className="embla" ref={emblaRef}>
                        <div className="embla__container">
                          <div className="embla__slide embla-item">
                            <p className="paragarph">
                              My project was dead and i didn't know what to do
                              anymore. Than Bam! few clicks and were back in
                              business 🙂 thank you VBot team!
                            </p>
                            <span className="heading">Lucas Bennett</span>
                            <span className="post">CEO</span>
                          </div>
                          <div className="embla__slide embla-item ">
                            <p className="paragarph">
                              There is only one thing to type. Thank you you
                              helped me suced once i applied you'r Vbot my Token
                              exploded. Thank you once again.
                            </p>
                            <span className="heading">Isabella Martinez</span>
                            <span className="post">CEO</span>
                          </div>
                          <div className="embla__slide embla-item">
                            <p className="paragarph">
                              Why didn't i find you guys earlier. Thousands of
                              dollars and many, many hours wasted. You changed
                              running my crypto projects from a hell ride to
                              smooth sailing.
                            </p>
                            <span className="heading">Hiroshi Tanaka</span>
                            <span className="post">CEO</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#testimonialcarousel"
                    data-bs-slide="prev"
                  >
                    <img
                      className="img-fluid"
                      alt=""
                      src={TestimonialPrevarrow}
                      onClick={scrollPrev}
                    />
                    <span className="previous">Prev</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#testimonialcarousel"
                    data-bs-slide="next"
                    onClick={scrollNext}
                  >
                    <img
                      className="img-fluid"
                      alt=""
                      src={TestimonialNextarrow}
                    />
                    <span className="next">Next</span>
                  </button>
                </div>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1 col-12"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TestimonialSection;
