import React from "react";
import Logo1 from "../../assets/images/logo1.png";
import Logo2 from "../../assets/images/logo2.png";
import Logo3 from "../../assets/images/logo3.png";
import Logo4 from "../../assets/images/logo4.png";
import Logo5 from "../../assets/images/logo5.png";
import Heading from "../../components/Heading";
const LogoSection: React.FC = () => {
  return (
    <section className="logo-section">
      <div className="container">
        <Heading
          tag="h4"
          text="Trusted By Over 150 Organization In More That 25+ Countries"
        />
        <ul className="mb-0 list-unstyled">
          <li>
            <figure className="mb-0 logo">
              <img className="img-fluid" src={Logo1} alt="" />
            </figure>
          </li>
          <li>
            <figure className="mb-0 logo">
              <img className="img-fluid" src={Logo2} alt="" />
            </figure>
          </li>
          <li>
            <figure className="mb-0 logo">
              <img className="img-fluid" src={Logo3} alt="" />
            </figure>
          </li>
          <li>
            <figure className="mb-0 logo">
              <img className="img-fluid" src={Logo4} alt="" />
            </figure>
          </li>
          <li>
            <figure className="mb-0 logo">
              <img className="img-fluid" src={Logo5} alt="" />
            </figure>
          </li>
        </ul>
      </div>
    </section>
  );
};
export default LogoSection;
