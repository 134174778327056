import React from "react";
import { Accordion } from "react-bootstrap";

const FaqSection: React.FC = () => {
  return (
    <>
      <section className="faq-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="faq_content">
                <h6>FAQ</h6>
                <h2>Frequently Asked Questions</h2>
              </div>
            </div>
          </div>
          <div className="faq">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <Accordion defaultActiveKey="1">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Antis unde omnis istye natus error?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="text-size-18 text-left mb-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. ipsum suspendisse ultrices gravida. Risus.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Antis unde omnis istye natus error?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="text-size-18 text-left mb-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. ipsum suspendisse ultrices gravida. Risus.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Antis unde omnis istye natus error?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="text-size-18 text-left mb-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. ipsum suspendisse ultrices gravida. Risus.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Antis unde omnis istye natus error?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="text-size-18 text-left mb-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. ipsum suspendisse ultrices gravida. Risus.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FaqSection;
