// src/components/Layout.tsx
import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "../../sections/Header";
import Footer from "../../sections/Footer";
import PreLoader from "../PreLoader";
import { Helmet } from "react-helmet";
import BannerRightlayer from "../../assets/images/banner-rightlayer.png";
import Leftbanner from "../../assets/images/banner-leftlayer.png";
import BannerToprightlayer from "../../assets/images/banner-toprightlayer.png";
import BannerSection from "../../sections/BannerSection";
import ScrollToTopButton from "../../components/BackToTop";
const Layout: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <>
      <Helmet>
        <title>VBot</title>
      </Helmet>
      <PreLoader isLoading={isLoading} />
      <ScrollToTopButton />
      <div className="future_of_robotics overflow-hidden-header">
        <figure className="banner-leftlayer mb-0">
          <img src={Leftbanner} alt="" className="img-fluid" />
        </figure>
        <figure className="mb-0 banner-toprightlayer">
          <img src={BannerToprightlayer} alt="" />
        </figure>
        <figure className="mb-0 banner-rightlayer">
          <img src={BannerRightlayer} alt="" />
        </figure>
        <Header />
        <BannerSection />
      </div>
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
