import React, { useEffect } from "react";
import $ from "jquery";
import "magnific-popup";
import "magnific-popup/dist/magnific-popup.css";

interface props {
  videoUrl: string;
}
const VideoPopup: React.FC<props> = ({ videoUrl }) => {
  useEffect(() => {
    ($(".popup-vimeo") as any).magnificPopup({
      type: "iframe",
      iframe: {
        patterns: {
          youtube: {
            index: "youtube.com/",
            id: "v=",
            src: "https://www.youtube.com/embed/%id%?autoplay=1",
          },
        },
      },
    });
  }, []);

  return (
    <div className="video-icon position-absolute">
      <div className="icon">
        <span>Watch</span>
        <a className="popup-vimeo" href={videoUrl}>
          <i
            className="thumb play-icon fa-solid fa-play"
            style={{ cursor: "pointer" }}
          ></i>
        </a>
      </div>
      <span className="text">Our Video</span>
    </div>
  );
};

export default VideoPopup;
