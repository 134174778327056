import React from "react";
import ServiceLogoimage from "../../assets/images/service-logoimage.png";

interface props {
  classes?: string;
  image?: string;
}
const Separator: React.FC<props> = ({ classes, image }) => {
  return (
    <figure className={classes ? classes : "mb-0 service-logoimage"}>
      <img
        src={image ? image : ServiceLogoimage}
        alt=""
        className="img-fluid"
      />
    </figure>
  );
};

export default Separator;
