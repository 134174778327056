import React from "react";
import AboutImage from "../../assets/images/MAIN-PICTURE-FINAL-compressed-2.webp";
import Heading from "../../components/Heading";
import Button from "../../components/Button";

const AboutSection: React.FC = () => {
  return (
    <section className="about-section">
      <div className="container position-relative">
        <div className="row">
          <div className="col-lg-7 col-md-12 col-sm-12 col-12">
            <div className="about_wrapper">
              <figure className="mb-0 about-image aos-init" data-aos="fade-up">
                <img className="img-fluid" src={AboutImage} alt="Banner" />
              </figure>
              <div className="box">
                <div className="year">
                  <div className="number">
                    <span className="mb-0 value counter">VBot</span>
                    <sup className="mb-0 plus">+</sup>
                  </div>
                  <span className="mb-0 text">
                    - The ultimate tool for crypto owners.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 col-12">
            <div className="about_content aos-init" data-aos="fade-up">
              <Heading tag="h6" text="About VBot" />
              <Heading
                tag="h2"
                text="Transaction Volume and token holders explosion."
              />
              <p className="text1 text-size-18">
                Ease of Use. Our intuitive interface and user-friendly design
                make it effortless to customize and optimize your
                volume-boosting strategies, putting the power of increased
                liquidity at your fingertips.
              </p>
              <p className="text2 text-size-18">
                Our goal is to make running a crypto project easier. Remove the
                risk factors and streamline the whole process. We ran many
                Tokens ourselves and realized that there is a gap in the market
                as so we decided to fill it.
              </p>
              <p className="text3 text-size-18">
                VBot is your one-stop solution for elevating your crypto
                project. Designed specifically for projects aiming to attract
                more investors and increase token visibility, VBot is the ideal
                tool to amplify trading volume, token holders and capture the
                crypto community.
              </p>
              <Button
                text="Access VBot"
                classes="read_more"
                link="https://t.me/+HofVwBPnu30wZmU9"
                target="_blank"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
