import React from "react";

interface props {
  link?: string;
  classes?: string;
  text?: string;
  target?: string;
  iconClass?: string;
}
const Button: React.FC<props> = ({
  link,
  classes,
  text,
  target,
  iconClass,
}) => {
  return (
    <>
      <a className={classes} href={link} target={target}>
        {text}
        <i
          className={iconClass ? iconClass : "circle fa-regular fa-angle-right"}
        ></i>
      </a>
    </>
  );
};

export default Button;
