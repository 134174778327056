import React from "react";
import TeamBackgroundimage from "../../assets/images/team-backgroundimage.png";
import PortfolioLeftlayer from "../../assets/images/portfolio-leftlayer.png";
import PortfolioRightlayer from "../../assets/images/portfolio-rightlayer.png";
import TeamImage1 from "../../assets/images/team-image1.png";
import TeamImage2 from "../../assets/images/team-image2.png";
import TeamImage3 from "../../assets/images/team-image3.png";
import TeamImage4 from "../../assets/images/team-image4.png";
import Heading from "../../components/Heading";
import Button from "../../components/Button";
const TeamSection: React.FC = () => {
  return (
    <>
      <section className="team-section">
        <figure className="team-backgroundimage mb-0">
          <img src={TeamBackgroundimage} className="img-fluid" alt="" />
        </figure>
        <figure className="team-leftlayer mb-0">
          <img src={PortfolioLeftlayer} className="img-fluid" alt="" />
        </figure>
        <figure className="team-rightlayer mb-0">
          <img src={PortfolioRightlayer} className="img-fluid" alt="" />
        </figure>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="team_content">
                <Heading tag="h6" text="Meet The Team" />
                <Heading tag="h2" text="We Are Intelligence In Action." />
              </div>
            </div>
          </div>
          <div className="row" data-aos="fade-up">
            <div className="col-lg-3 col-md-3 col-sm-6 col-12">
              <div className="image">
                <figure className="team-image mb-0">
                  <img src={TeamImage1} className="img-fluid" alt="" />
                </figure>
                <div className="box-content">
                  <div className="content">
                    <ul className="list-unstyled mb-0">
                      <li className="icons">
                        <a href="#">
                          <i className="fa-brands fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="icons">
                        <a href="#">
                          <i className="fa-brands fa-twitter"></i>
                        </a>
                      </li>
                      <li className="icons">
                        <a href="#">
                          <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                    <Heading tag="h4" text="Alexandra Sander" />
                    <span className="text-size-16">Security Officer</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-12">
              <div className="image image2">
                <figure className="team-image mb-0">
                  <img src={TeamImage2} className="img-fluid" alt="" />
                </figure>
                <div className="box-content">
                  <div className="content">
                    <ul className="list-unstyled mb-0">
                      <li className="icons">
                        <a href="#">
                          <i className="fa-brands fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="icons">
                        <a href="#">
                          <i className="fa-brands fa-twitter"></i>
                        </a>
                      </li>
                      <li className="icons">
                        <a href="#">
                          <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                    <Heading tag="h4" text="Alexandra Sander" />
                    <span className="text-size-16">Security Officer</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-12">
              <div className="image">
                <figure className="team-image mb-0">
                  <img src={TeamImage3} className="img-fluid" alt="" />
                </figure>
                <div className="box-content">
                  <div className="content">
                    <ul className="list-unstyled mb-0">
                      <li className="icons">
                        <a href="#">
                          <i className="fa-brands fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="icons">
                        <a href="#">
                          <i className="fa-brands fa-twitter"></i>
                        </a>
                      </li>
                      <li className="icons">
                        <a href="#">
                          <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                    <Heading tag="h4" text="Alexandra Sander" />
                    <span className="text-size-16">Security Officer</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-12">
              <div className="image image2">
                <figure className="team-image mb-0">
                  <img src={TeamImage4} className="img-fluid" alt="" />
                </figure>
                <div className="box-content">
                  <div className="content">
                    <ul className="list-unstyled mb-0">
                      <li className="icons">
                        <a href="#">
                          <i className="fa-brands fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="icons">
                        <a href="#">
                          <i className="fa-brands fa-twitter"></i>
                        </a>
                      </li>
                      <li className="icons">
                        <a href="#">
                          <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                    <Heading tag="h4" text="Alexandra Sander" />
                    <span className="text-size-16">Security Officer</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="button">
            <Button classes="view_more" link="/about" text="View All" />
          </div>
        </div>
      </section>
    </>
  );
};

export default TeamSection;
