import React from "react";
import EndLogo from "../../assets/images/end-logo.png";
import ServiceIcon1 from "../../assets/images/service-icon1.png";
import ServiceIcon2 from "../../assets/images/service-icon2.png";
import ServiceIcon3 from "../../assets/images/service-icon3.png";
import ServiceIcon4 from "../../assets/images/service-icon4.png";
import ServiceImage1 from "../../assets/images/pic-19-1.webp";
import ServiceImage2 from "../../assets/images/pic-18-1.webp";
import Separator from "../../components/Separator";
import Heading from "../../components/Heading";
import ServiceLogoimage from "../../assets/images/service-logoimage.png";

interface props {
  classes?: string;
  showSeparator?: boolean;
}
const ServiceSection: React.FC<props> = ({ classes, showSeparator }) => {
  return (
    <>
      <section className={`service-section ${classes}`}>
        <figure className="mb-0 service-logoimage">
          <img src={ServiceLogoimage} alt="" className="img-fluid" />
        </figure>

        <div className="container">
          {showSeparator && (
            <Separator classes="mb-0 end-logo" image={EndLogo} />
          )}
          <div className="content">
            <div className="row" id="features">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="service_content">
                  <Heading tag="h6" text="Tools for Project owners." />
                  <Heading text="Vbot's capabilities." tag="h2" />
                  <div className="service_contentbox" data-aos="fade-up">
                    <div className="upper-row">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <div className="service-box">
                            <figure className="service-icon">
                              <img
                                src={ServiceIcon1}
                                alt=""
                                className="img-fluid"
                              />
                            </figure>
                            <span>Volume Booster Service</span>
                            <p className="text-size-16">
                              Maximize your transaction Volume and Token holders
                              on BSC, ETH, or SOL. Start the fomo now!
                            </p>
                            <a
                              className="read_more text-decoration-none"
                              href=""
                            >
                              Read More
                              <i className="fa-solid fa-chevron-right"></i>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <div className="service-box">
                            <figure className="service-icon">
                              <img
                                src={ServiceIcon2}
                                alt=""
                                className="img-fluid"
                              />
                            </figure>
                            <span>Deployer Sniper</span>
                            <p className="text-size-16">
                              Deployer Sniper Use VBot to add Lp and than snipe
                              the supply in the first transactions. Say no
                              snipers wrecking your project.
                            </p>
                            <a
                              className="read_more text-decoration-none"
                              href=""
                            >
                              Read More
                              <i className="fa-solid fa-chevron-right"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="service-box">
                          <figure className="service-icon">
                            <img
                              src={ServiceIcon3}
                              alt=""
                              className="img-fluid"
                            />
                          </figure>
                          <span>Auto Trending</span>
                          <p className="text3 text-size-16">
                            Use VBot to streamline trending on various websites
                            and Telegram channels with a few clicks.
                          </p>
                          <a
                            className="read_more text-decoration-none"
                            href="#"
                          >
                            Read More
                            <i className="fa-solid fa-chevron-right"></i>
                          </a>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="service-box">
                          <figure className="service-icon">
                            <img
                              src={ServiceIcon4}
                              alt=""
                              className="img-fluid"
                            />
                          </figure>
                          <span>Automated staking</span>
                          <p className="text4 text-size-16">
                            Use VBot to create your Token staking in minutes. No
                            more headaches with devs or broken code.
                          </p>
                          <a
                            className="read_more text-decoration-none"
                            href="#"
                          >
                            Read More
                            <i className="fa-solid fa-chevron-right"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="service_wrapper">
                  <p className="mb-0 text5 text-size-18">
                    VBot has many amazing solutions for Crypto Project owners.
                    And is always adding more. If you dreamed about any feature
                    message us if we like it we will develop it for free and add
                    it to VBot.
                  </p>
                  <figure className="service-image1 mb-0" data-aos="fade-right">
                    <img src={ServiceImage1} alt="" className="img-fluid" />
                  </figure>
                  <figure className="service-image2 mb-0" data-aos="fade-left">
                    <img src={ServiceImage2} alt="" className="img-fluid" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ServiceSection;
