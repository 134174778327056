import React from "react";
import Button from "../../components/Button";
import Heading from "../../components/Heading";
import TechnologyLogoimage from "../../assets/images/technology-logoimage.png";
import TechnologyImage1 from "../../assets/images/technology-image1.png";
import TechnologyImage2 from "../../assets/images/technology-image2.png";

const TechnologySection: React.FC = () => {
  return (
    <>
      <section className="technology-section">
        <figure className="mb-0 technology-logoimage">
          <img src={TechnologyLogoimage} alt="" className="img-fluid" />
        </figure>
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="technology_wrapper">
                  <figure className="technology-image1 mb-0">
                    <img src={TechnologyImage1} alt="" className="img-fluid" />
                  </figure>
                  <figure className="technology-image2 mb-0">
                    <img src={TechnologyImage2} alt="" className="img-fluid" />
                  </figure>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="technology_content">
                  <Heading tag="h6" text="Science and Technology" />
                  <Heading
                    tag="h2"
                    text="We Create Robots That Can Help You."
                  />
                  <p className="text1 text-size-18">
                    We Believe That Technology Can Help Human Beings To A Better
                    Life.
                  </p>
                  <p className="text2 text-size-18">
                    In enim justo, rhoncus ut, imperdiet a, venenatis vitae,
                    justo. Nullam dictum felis eu pede mollis pretium. Integer
                    tincidunt. Cras dapibus. Vivamus elementum semper nisi.
                    Aenean vulputate eleifend tellus.
                  </p>
                  <p className="text3 text-size-18">
                    In enim justo, rhoncus ut, imperdiet a, venenatis vitae,
                    justo. Nullam dictum felis eu pede mollis pretium.
                  </p>
                  <Button link="/about" classes="read_more" text="Read More" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default TechnologySection;
