import React from "react";
import Banner from "../../components/Banner";
import LogoSection from "../../sections/LogoSection";
import AboutSection from "../../sections/About";
import ValueSection from "../../sections/ValueSection";
import TeamSection from "../../sections/TeamSection";
import TechnologySection from "../../sections/TechnologySection";
import TestimonialSection from "../../sections/TestimonialSection";

const About: React.FC = () => {
  return (
    <>
      <Banner title="About Us" breadCrumb="About" />
      <LogoSection />
      <AboutSection />
      <ValueSection />
      <TeamSection />
      <TechnologySection />
      <TestimonialSection showLogoImage={false} />
    </>
  );
};

export default About;
