import React from "react";
import ContactportionBackground from "../../assets/images/contactportion-backgroundimage.png";
import ContactportionImage from "../../assets/images/Unsaved-Image-2-1-1.webp";
import Logo from "../../assets/images/3d-logo.webp";

const Footer: React.FC = () => {
  return (
    <>
      <div className="robotics_footer_portion">
        <div className="upper-portion">
          <div className="container">
            <div className="contact_portion">
              <figure className="contactportion-backgroundimage mb-0">
                <img
                  src={ContactportionBackground}
                  className="img-fluid"
                  alt=""
                />
              </figure>
              <div className="row">
                <div className="col-lg-4 col-md-5 col-sm-5 col-12"></div>
                <div className="col-lg-8 col-md-7 col-sm-7 col-12">
                  <div className="content aos-init" data-aos="fade-up">
                    <h6>Let's Make Something Great Together</h6>
                    <h2>
                      Need any thing developed. That's what we do so don't
                      hesitate to msg us!
                    </h2>
                    <a className="contact_us" href="#contact">
                      Contact Us
                      <i className="circle fa-regular fa-angle-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <figure className="contactportion-image mb-0">
                <img src={ContactportionImage} className="img-fluid" alt="" />
              </figure>
            </div>
          </div>
        </div>
        <div className="footer-section">
          <div className="container">
            <div className="middle-portion">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="footerLogoWithDescription">
                    <a href="./index.html">
                      <figure className="footer-logo">
                        <span className="leftEye eye"></span>
                        <span className="rightEye eye"></span>
                        <img src={Logo} className="img-fluid" alt="" />
                      </figure>
                    </a>

                    <p className="text-size-16 footer-text">
                      Take your crypto project to another level.
                    </p>
                  </div>
                  <ul className="list-unstyled mb-0 social-icons">
                    <li>
                      <a href="#" className="text-decoration-none">
                        <i className="fa-brands fa-facebook-f social-networks"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="text-decoration-none">
                        <i className="fa-brands fa-twitter social-networks"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/105491441"
                        target="_blank"
                        className="text-decoration-none"
                      >
                        <i className="fa-brands fa-linkedin-in social-networks"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12 d-sm-block">
                  <div className="icon">
                    <ul className="list-unstyled mb-0">
                      <li className="text">
                        <i className="fa fa-phone fa-icon footer-location"></i>
                        <a
                          href="https://t.me/+HofVwBPnu30wZmU9"
                          className="mb-0 text text-decoration-none text-size-16"
                        >
                          https://t.me/+HofVwBPnu30wZmU9
                        </a>
                      </li>
                      <li className="text">
                        <i className="fa fa-envelope fa-icon footer-location"></i>
                        <a
                          href="mailto:support@vbot.bot"
                          className="mb-0 text text-decoration-none text-size-16"
                        >
                          support@vbot.bot
                        </a>
                      </li>
                      <li className="text">
                        <i className="fa-solid fa-location-dot footer-location footer-location3"></i>
                        <p className="text1 text-size-16">
                          <a
                            href="https://t.me/+HofVwBPnu30wZmU9"
                            className="mb-0 text text-decoration-none text-size-16"
                            target="_blank"
                          >
                            VBot Telegram
                          </a>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12 d-lg-block d-none">
                  <div className="email-form">
                    <form action="javascript:;">
                      <div className="form-group position-relative">
                        <input
                          type="text"
                          className="form_style"
                          placeholder="Enter Email:"
                          name="email"
                        />
                        <button>
                          <i className="send fa-sharp fa-solid fa-paper-plane"></i>
                        </button>
                      </div>
                      <div className="form-group check-box">
                        <input type="checkbox" id="term" />
                        <label htmlFor="term">
                          Subscribe and dont miss our development updates!
                        </label>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="copyright">
              <div className="row">
                <div className="col-12">
                  <p className="mb-0 text-size-16 text-white">
                    Copyright © 2024 VBot. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
