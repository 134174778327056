import React, { useState, useEffect, useRef } from "react";
import "./index.scss";

interface CalculatorProps {
  packageType: string;
  onValueChange: (value: number) => void; // Add this prop to pass the value to the parent
}

const BootCalculator: React.FC<CalculatorProps> = ({
  packageType,
  onValueChange,
}) => {
  const [value, setValue] = useState<number>(1);
  const [convertedValue, setConvertedValue] = useState<number>(1);

  const sliderRef = useRef<HTMLInputElement>(null);
  const sliderValueRef = useRef<HTMLDivElement>(null);
  const minValueRef = useRef<HTMLDivElement>(null);
  const maxValueRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const slider = sliderRef.current;
    const sliderValue = sliderValueRef.current;
    const minValue = minValueRef.current;
    const maxValue = maxValueRef.current;

    if (slider && sliderValue) {
      const sliderWidth = slider.clientWidth;
      const thumbWidth = sliderWidth * (value / 100);
      const progress = (thumbWidth / sliderWidth) * 100;

      const newConvertedValue = Math.round(value / 5);
      setConvertedValue(newConvertedValue < 1 ? 1 : newConvertedValue);

      slider.style.setProperty("--progress", `${progress}%`);
      sliderValue.style.left =
        progress < 50 ? `${progress}%` : `calc(${progress}% - 25px)`;

      const color = "#2b9ef0";
      // switch (packageType) {
      //   case 'starter':
      //     color = '#657bf5';
      //     break;
      //   case 'growth':
      //     color = '#33b1fb';
      //     break;
      //   case 'alpha':
      //     color = '#c400f4';
      //     break;
      //   case 'ecosystem':
      //     color = '#1bffa0';
      //     break;
      //   default:
      //     color = '#657bf5';
      // }
      // slider.style.setProperty('--slider-color', color);
      // sliderValue.style.setProperty('color', color);
      // minValue?.style.setProperty('color', color);
      // maxValue?.style.setProperty('color', color);
    }
  }, [value, packageType]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    setValue(newValue);
    onValueChange(convertedValue);
  };

  return (
    <div className={`sliderContainer`}>
      <input
        type="range"
        min="0"
        max="100"
        value={value}
        onChange={handleChange}
        className="sliderInput"
        ref={sliderRef}
      />
      <div className="minRangeValue">1x</div>
      <div className="maxRangeValue">20x</div>
      <div className="valueBubble" ref={sliderValueRef}>
        {`${convertedValue}x`}
      </div>
    </div>
  );
};

export default BootCalculator;
