import React, { useState } from "react";
import "./index.scss";
interface Props {
  handleSelectTime: (selectedType: string) => void;
}

const Switch: React.FC<Props> = ({ handleSelectTime }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [time, setTime] = useState("6h");

  const handleToggle = (e: any) => {
    setIsChecked((prevState) => !prevState);
    setTimeout(() => {
      setTime(e.target.value);
      handleSelectTime(e.target.value);
    }, 400);
  };

  return (
    <>
      <label className="switch">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleToggle}
          value={isChecked ? "24h" : "6h"}
        />
        <span className={`slider ${isChecked ? "leftText" : "rightText"}`}>
          <span className="slider-text">
            {isChecked ? "24h" : "6h"}
            <sup>*</sup>
          </span>
        </span>
      </label>
    </>
  );
};

export default Switch;
