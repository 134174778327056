import React from "react";

interface props {
  tag: string;
  text: string;
  classes?: string;
}

const Heading: React.FC<props> = ({ tag, text, classes }) => {
  switch (tag) {
    case "h1":
      return (
        <h1 className={classes}>
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
        </h1>
      );
    case "h2":
      return <h2 className={classes}>{text}</h2>;
    case "h3":
      return <h3 className={classes}>{text}</h3>;
    case "h4":
      return <h4 className={classes}>{text}</h4>;
    case "h5":
      return <h5 className={classes}>{text}</h5>;
    case "h6":
      return <h6 className={classes}>{text}</h6>;
  }

  return null;
};
export default Heading;
